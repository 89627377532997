<template>
    <div>
      <v-col cols="12" class="pt-0">
        <v-row>
          <v-col cols="3">
            <datePicker
              v-model="filtros.desde"
              format="YYYY-MM-DD"
              :maxDate="filtros.hasta"
              label="Desde (Obligatorio)"
              clearable
              maxToday
              @input="getInfo()"
              :disabled="cargando"
            ></datePicker>
          </v-col>
          <v-col cols="3">
            <datePicker
              v-model="filtros.hasta"
              format="YYYY-MM-DD"
              :minDate="filtros.desde"
              label="Hasta (Obligatorio)"
              clearable
              @input="getInfo()"
              :disabled="cargando"
            ></datePicker>
          </v-col>
          <v-col cols="6" class="text-right">
            <v-btn
              color="primary"
              :disabled="disabledBtnRefresh"
              @click="getInfo()"
            >
              <v-icon left>mdi-refresh</v-icon> Refrescar
            </v-btn>
          </v-col>
          <v-col cols="12" md="12" sm="12">
            <v-row class="d-flex justify-center">
              <v-col cols="12" md="3" sm="12">
                <v-card class="fill-height d-flex">
                  <v-card-text class="text-center align-self-center">
                    <div class="subtitle-1 font-weight-bold">
                      Kilogramos Usados
                    </div>
                    <!-- :endVal="estadisticas.montoTotalEstimadoProduccion" -->
                    <ICountUp
                      v-if="loadingEstadisticas"
                      :delay="1000"
                      :endVal="this.apex.kgUtilizados"
                      class="subtitle-1 font-weight-medium"
                      :options="{
                        decimalPlaces: 2,
                        separator: ',',
                        decimal: '.',
                        prefix: '',
                      }"
                    />
                    <div v-else class="text-center">
                      <v-progress-circular
                        :size="25"
                        color="primary"
                        indeterminate
                      ></v-progress-circular>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="3" sm="12">
                <v-card class="fill-height d-flex">
                  <v-card-text class="text-center align-self-center">
                    <div class="subtitle-1 font-weight-bold">
                      Cantidad de Laminas
                    </div>
                    <!-- :endVal="estadisticas.montoTotalEstimadoProduccion" -->
                    <ICountUp
                      v-if="loadingEstadisticas"
                      :delay="1000"
                      :endVal="this.apex.cantidadLaminas"
                      class="subtitle-1 font-weight-medium"
                      :options="{
                        decimalPlaces: 2,
                        separator: ',',
                        decimal: '.',
                        prefix: '',
                      }"
                    />
                    <div v-else class="text-center">
                      <v-progress-circular
                        :size="25"
                        color="primary"
                        indeterminate
                      ></v-progress-circular>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="3" sm="12">
                <v-card class="fill-height d-flex">
                  <v-card-text class="text-center align-self-center">
                    <div class="subtitle-1 font-weight-bold">
                      Piezas Fabricadas
                    </div>
                    <ICountUp
                      v-if="loadingEstadisticas"
                      :delay="1000"
                      :endVal="this.apex.pzaFabricadas"
                      class="subtitle-1 font-weight-medium"
                      :options="{
                        decimalPlaces: 2,
                        separator: ',',
                        decimal: '.',
                        prefix: '',
                      }"
                    />
                    <div v-else class="text-center">
                      <v-progress-circular
                        :size="25"
                        color="primary"
                        indeterminate
                      ></v-progress-circular>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="12" sm="12" v-if="cargando">
            <v-progress-linear
              color="primary"
              height="5"
              indeterminate
            ></v-progress-linear>
          </v-col>
          <v-col v-else cols="12" md="12" sm="12">
            <!-- <apexchart
              type="bar"
              height="350"
              :options="chartOptions"
              :series="series"
            ></apexchart> -->
            <apexchart
              type="bar"
              height="500"
              :options="chartOptions"
              :series="series"
            ></apexchart>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
      <v-simple-table>
        <thead>
          <tr>
            <th v-for="(item, index) in headers" :key="index" class="text-center" style="min-width: 400px;" v-if="index % 2 === 0">
              <span class="text-h6">{{ item.text }}</span>
              <div class="subheaders" >
                <div class="d-flex flex-column align-items-center">
                  <th class="text-center text-subtitle-1 mx-3" style="white-space: nowrap;">M.P. Teórica</th>
                  <th class="text-center">
                    
                    {{ (Number(items[index][0]).toFixed(2)).replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}
                  </th>
                </div>
                <div class="d-flex flex-column align-items-center">
                  <th class="text-center text-subtitle-1 mx-3" style="white-space: nowrap;">M.P. Real</th>
                  <th class="text-center">
                    {{ (Number(items[index+1][3]).toFixed(2)).replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}
                  </th>
                </div>
                <div class="d-flex flex-column align-items-center">
                  <th class="text-center text-subtitle-1 mx-3" style="white-space: nowrap;">Merma Teórica</th>
                  <th class="text-center">
                    {{ (Number(items[index][2]).toFixed(2)).replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}
                  </th>
                </div>
                <div class="d-flex flex-column align-items-center">
                  <th class="text-center text-subtitle-1 mx-3" style="white-space: nowrap;">Merma Real</th>
                  <th class="text-center">
                    {{ (Number(items[index+1][5]).toFixed(2)).replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}
                  </th>
                </div>
                <div class="d-flex flex-column align-items-center">
                  <th class="text-center text-subtitle-1 mx-3" style="white-space: nowrap;">Scrap Teórico</th>
                  <th class="text-center">
                    {{ (Number(items[index][1]).toFixed(2)).replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} 
                  </th>
                </div>
                <div class="d-flex flex-column align-items-center">
                  <th class="text-center text-subtitle-1 mx-3" style="white-space: nowrap;">Scrap Real</th>
                  <th class="text-center">
                    {{ (Number(items[index+1][4]).toFixed(2)).replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}
                  </th>
                </div>
              </div> 
            </th>
          </tr>
        </thead>
      </v-simple-table>
    </v-col>
    </div>
  </template>
    
    <script>
  import axios from "axios";
  import ICountUp from "vue-countup-v2";
  /* 
    import { mapState } from "vuex"; */
  /* import DownloadBtn from "../../../DownloadBtn.vue"; */
  
  export default {
    components: {
      ICountUp,
    },
    data() {
      return {
        loadingEstadisticas: true,
        cargando: false,
        filtros: {
          desde: null,
          hasta: null,
        },
        categories: [],
        series: [],
        loadingMaquinas: false,
        apex: {
          kgUtilizados: 0,
          cantidadLaminas: 0,
          pzaFabricadas: 0,
        },
        colors: [
          '#008FFB',
          '#00E396',
          '#FEB019',
          '#FF4560',
          '#775DD0',
          '#546E7A',
          '#26a69a',
          '#D10CE8'
        ],

        headers: [],
        items: [],
        items2: [],
      };
    },
    mounted() {
      this.initialize();
    },
    computed: {
      disabledBtnRefresh(){
        return this.cargando || Object.values(this.filtros).some(value => value == null);
      },
      chartOptions() {
        return {
          colors: this.colors,
          states: {
            active: {
              allowMultipleDataPointsSelection: false,
            },
          },
          chart: {
            type: "bar",
            height: 500,
            barWidth: '10',
            stacked: true,
            toolbar: {
              show: true,
            },
            zoom: {
              enabled: true,
            },
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: "100%",
              dataLabels: {
                total: {
                  enabled: true,
                  style: {
                    fontSize: "13px",
                    fontWeight: 900,
                  },
                },
              },
            },
          },
          dataLabels: {
            enabled: false,
            formatter: function (val) {
              return val.toFixed(2)+' Kg';
            }
          },
          stroke: {
            show: true,
            width: 2,
            colors: ["transparent"],
          },
          xaxis: {
            categories: this.categories,
             labels: {
              maxHeight: 200,
            }
          },
          yaxis: {
            labels: {
              formatter: function (val) {
                return val.toFixed(2)+' Kg';
              }
            }
          },
          fill: {
            opacity: 1,
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val.toFixed(2)+' Kg';
              },
            },
          },
        };
      },
    },
    methods: {
      initialize() {
        this.getInfo();
      },
      getInfo() {  //Función que conecta con el end point para obtener datos a mostrar en tabla. 
        this.headers = [];
      this.items = [];
        if (this.filtros.desde == null) return;
        if (this.filtros.hasta == null) return;

        this.cargando = true;
        this.loadingEstadisticas = false;
        axios
          .get(`/Reportes/ReporteGeneralFlujo/`, {
            params: {
                desde: this.filtros.desde,
                hasta: this.filtros.hasta,
            },
          })
          .then((response) => {
            this.categories = response.data.categorias;
            this.series = response.data.series;
            this.apex.kgUtilizados = response.data.kgUtilizados;
            this.apex.cantidadLaminas = response.data.cantidadLaminas;
            this.apex.pzaFabricadas = response.data.pzaFabricadas;

            let dataTable = response.data.series;
            let categoriasTable = response.data.categorias;
            this.tableGenerate(dataTable, categoriasTable);

            this.cargando = false;
            this.loadingEstadisticas = true;
          })
          .catch((error) => {
            console.log(error);
            this.cargando = false;
            this.loadingEstadisticas = true;
          });
      },

      tableGenerate(dataTable, categoriasTable) {
        // console.log(dataTable)
        // console.log(categoriasTable)
        let newHeaders = [];
        let newData = [];
        const series = ["M.P Teorica", "Scrap Teorica", "Merma Teorica", "M.P Real", "Scrap Real", "Merma Real"]



        const filteredArray = categoriasTable.filter(item => !item.toLowerCase().includes("total"));
        const areas = filteredArray.map(item => item.split(" - ")[0]);
        const uniqueAreas = [...new Set(areas)];
        // const uniqueAreas = [...new Set(categoriasTable)];
        const toCamelCase = (str) => {
          return str
            .split(" ")
            .map((word, index) => {
              if (index === 0) return word.toLowerCase();
              return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
            })
            .join("");
        };

        uniqueAreas.forEach((area, index) => {
        newHeaders.push({
          text: area,
          value: area.toLowerCase().replace(/\s+/g, ''),
          align: "center",
          sortable: false
        });

        // Si no es el último elemento, agregar un objeto vacío
        if (index < uniqueAreas.length - 1) {
          newHeaders.push({});
        }
});

        let info = dataTable.map(item => item.data);
        const summed = info[0].map((_, i) => 
          info.reduce((sum, row) => sum + row[i], 0)
        );
        const transpuesta = info[0].map((_, i) => info.map(fila => fila[i]));

        this.items = transpuesta;
        this.items2 = info
        this.headers = newHeaders;
      },
      valueData(item, idx){
        let valores = Object.values(item);
        return valores[idx]
      },
    },
  };
  </script>
  <style>
  .subheaders {
    display: flex;
    justify-content: space-evenly;
    margin-top: 5px;
  }
  
  .subheader {
    text-align: center;
    font-size: 12px;
    margin: 0 80px 0 80px;
    color: #616161;
  }
  </style>